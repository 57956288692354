<template>
  <v-card color="transparent" tile elevation="0">
    <v-card class="py-sm-8 py-4" color="light_gray" tile elevation="0">
      <v-row justify="center" no-gutters>
        <v-col cols="11" lg="9">
          <span class="text-sm-h5 text-body-1 font-weight-bold">{{ $t(`label.cancelOrder`) }}</span>
        </v-col>
      </v-row>
    </v-card>

    <v-row justify="center" class="my-8" no-gutters>
      <v-col cols="11" lg="9">
        <div>
          <p class="text-h6">{{ $t(`label.yourOrderHadBeenCancel`) }}</p>
        </div>
        <v-card class="pa-6 px-sm-8 px-4 rounded-xl" color="transparent" tile elevation="15">
          <div>
            <div class="history-container unbox_scrollbar pr-sm-4 pr-0 mb-6">
              <div class="mb-16" v-if="order !== null">
                <app-order-summary :detail="order" :isPayment="true"></app-order-summary>
              </div>
            </div>
          </div>

          <div class="mt-10">
            <v-row justify="center">
              <v-col cols="12" sm="5" md="4">
                <v-btn outlined block color="black" class="rounded-lg font-weight-bold text-body-1 py-8" @click="contactUnbox">
                  <v-icon class="stroke--black mr-2" v-text="'$whatappIcon'" />
                  {{ $t(`label.contactUnbox`) }}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="5" md="4">
                <v-btn block color="black" class="rounded-lg font-weight-bold white--text text-body-1 py-8" @click="goToOrderHistory">
                  {{ $t(`label.viewHistory`) }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import AppOrderSummary from '@/components/history/orderSummary.vue'
import { ROUTE_NAME } from '@/constants/route.constants'
export default {
  components: {
    AppOrderSummary
  },
  data: () => ({}),
  computed: {
    order() {
      return this.$store.state.order.order
    }
  },
  methods: {
    contactUnbox() {
      window.open('https://api.whatsapp.com/send/?phone=601155550000&text&app_absent=0', '_blank')
    },
    goToOrderHistory() {
      this.$router.push({
        name: ROUTE_NAME.HISTORY_HISTORY
      })
    }
  }
}
</script>

<style lang="scss">
.orderHistory-box {
  border: 1px solid #d5dde0;
}
.history-table-left-info {
  width: 180px;
}

.history-product-image-box {
  border: 1px solid var(--v-light_gray-base);
}
</style>
